import { Link } from "gatsby";
import { get, kebabCase } from "lodash";
import * as React from "react";
import { Item, List, Grid, Button, Container, Label, GridColumn } from "semantic-ui-react";
import { MarkdownRemark } from "../../graphql-types";

interface JobsListProps {
  jobs: MarkdownRemark[];
}

const JobsList = (props: JobsListProps ) => {
  const jobs = props.jobs;
  return (
    <Item.Group relaxed divided>
      {jobs.map((jobNode) => {
        const { frontmatter, fields: { slug } } = jobNode;
        const image = get(frontmatter, "image.children.0.fixed", {});
        const extra = frontmatter.tags && (
          <div>
            {frontmatter.tags.map((tag: string) => {
              return <Label as={Link} to={`/jobs/tags/${kebabCase(tag)}`} style={{marginBottom: "3px"}}>{tag}</Label>;
            })}
          </div>
        );

        return (
          <Item key={slug}>
            {/* <Link to={slug}> */}
              <Item.Image
                size="tiny"
                srcSet={image.srcSet}
                href={slug}
                as="a"
              />
            {/* </Link> */}
            <Item.Content>
              <Item.Header>
                <Link to={slug} style={{color: "black"}}>{frontmatter.organization}</Link>
              </Item.Header>
              <Item.Meta style={{marginTop: 0}}>
                <List horizontal bulleted size="large">
                  <List.Item>
                    <List.Content>
                      {frontmatter.jobTitle}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      {frontmatter.location}
                    </List.Content>
                  </List.Item>
                </List>
              </Item.Meta>
              <Item.Extra>
                {extra}
                <Grid>

                  <GridColumn mobile={16} computer={12} width={16} style={{paddingLeft: 0, paddingRight: 0}}>
                    <Link to={slug}>
                      <Button primary fluid>Learn More</Button>
                    </Link>
                  </GridColumn>
                </Grid>

              </Item.Extra>
            </Item.Content>
          </Item>
        );
      })}
    </Item.Group>
  );
};

export default JobsList;
