import * as React from "react";
import { Header, Segment, Icon } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

interface SectionTitleProps {
  title: string;
  subtitle?: string;
}

export default (props: SectionTitleProps) => {
  return (
    <Segment vertical>
      <Header as="h2">
        <Header.Content>
          {props.title}
          {props.subtitle &&
            <Header.Subheader>
              {props.subtitle}
            </Header.Subheader>
          }
        </Header.Content>
      </Header>
    </Segment>
  );
};
