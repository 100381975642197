import * as React from "react";
import { graphql } from "gatsby";
import SectionTitle from "../components/SectionTitle";
import { Grid, Container, Segment } from "semantic-ui-react";
import { MarkdownRemarkConnection } from "../graphql-types";
import {withLayout, LayoutProps} from "../components/Layout";
import JobsList from "../components/JobsList/JobsList";

interface IndexPageProps extends LayoutProps {
  data: {
    jobs: MarkdownRemarkConnection;
  };
  pageContext: {
    tag?: string; // only set into `templates/tags-pages.tsx`
  };
}

const JobsPage = (props: IndexPageProps) => {
  const jobs = props.data.jobs.edges;
  const tag = props.pageContext.tag;

  const Jobs = (
    <Container style={{marginBottom: "20px"}}>
      <SectionTitle
        title={`Jobs: ${tag}`}
      />
      <JobsList
        jobs={jobs.map((jobEdge) => jobEdge.node)}
      />
    </Container>
  );

  return (
    <Container>
      {/* Content */}
      <Segment vertical>
        <Grid padded style={{ justifyContent: "space-around" }}>
          <Grid.Row>
            <div style={{ maxWidth: 600 }}>
              {Jobs}
            </div>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

export default withLayout(JobsPage);

export const pageQuery = graphql`
query TemplateTagJobsPage($tag: String) {
  jobs: allMarkdownRemark(
    filter: {
      frontmatter: {
        draft: { ne: true }
        tags: { in: [$tag] }
      },
      fileAbsolutePath: {regex: "/\/jobs\//"},
      excerpt: {}
    }
    sort:{ order:DESC, fields:[frontmatter___rank]}
  ) {
    edges {
      node {
        frontmatter {
          organization
          jobTitle
          location
          url
          tags
          image {
            children {
              ... on ImageSharp {
                fixed(width: 120) {
                	src
            			srcSet
                }
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`;
